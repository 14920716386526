import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import Gallery from '../components/common/gallery';

const IndexPage = () => (
  <>
    <Gallery
      imageLinks={
        [1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
          `https://s3.amazonaws.com/valentina-site/main_images/main${number}.jpg`
        ))
      }
      navigationColor='#c3c6cc'
    />
    <WasteWithAFPortal />
  </>
)

export default IndexPage

class WasteWithAFPortal extends Component {
  componentDidMount() {
    this.forceUpdate()
  }

  render() {
    return (
      typeof window !== 'undefined' &&
        window.document.querySelector('.layout-grid-wrapper') ?
        ReactDOM.createPortal(
          <div
            style={{
              gridArea: '4 / 3 / auto / auto',
              alignSelf: 'end',
              justifySelf: 'center',
              color: '#5484c2',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >waste with a (f)</div>,
          window.document.querySelector('.layout-grid-wrapper')
        ) : null
    )
  }
}